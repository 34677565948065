/* eslint-disable */
import React from "react"
import Title from "../../Title"
import styles from "../../../css/buidl.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Utils from "../../../utils"
import Keys from '../../../constants/keys'
import { navigate } from "gatsby"
import loadable from '@loadable/component'

const Web3Login = loadable(() => import("../Login/Web3Login"))

class Buidl extends React.Component {
  constructor() {
    super();
    this.state = {message: ""}
  }
  render() {
    let location = this.props.location;
    const handleSubmit = (e) => {
      e.preventDefault()
      let target = e.target
      if(target.elements.orgname.value == 0) {
        this.setState({message: "Please provide an organization name"})
        return
      }
      if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(target.elements.email.value)) {
        this.setState({message: "Please provide a valid email address"})
        return
      }
      if(Utils.getQueryVariable(location, "r")) {
        if(typeof window != "undefined" && window) {
          window.localStorage.setItem("referral_code", Utils.getQueryVariable(location, "r"));
        }
      }
      window.grecaptcha.execute(Keys.recaptcha_site_key, {action: 'signup'}).then((recaptchaToken) => {
        return fetch("/api/account", {
          method: "POST",
          body: JSON.stringify({
            "email": target.elements.email.value,
            "org": target.elements.orgname.value,
            "recaptcha_token": recaptchaToken,
            "plan": "BUIDL",
            "invite_code": Utils.getQueryVariable(location, "i"),
            "referral_code": Utils.getQueryVariable(location, "r"),
          }),
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Utils.getCookie('csrftoken'),
          }
        }).then(function(response) { return response.json() }).then((data) => {
          if(data.ok) {
            if(typeof(dataLayer) == 'object') {
              dataLayer.push({'event': 'signup-start'});
            }
            navigate(`/activate/?a=${data.activation_id}`);
          } else {
            if(data.error == "bad-invite-code"){
              this.setState({message: "Your invite code is no longer valid"});
            } else if(data.error == "duplicate-user") {
              this.setState({message: "This email address is already in use."});
            } else {
              this.setState({message: "An unknown error has occurred"});
            }
          }
          return
        });
        console.log()
      })
    }
    const handleLoginError = (error) => {
      this.setState({message: error})
    }
    return (
      <section className={styles.buidl}>
      <span className={styles.modalHeader}><Title title="Get Started" subtitle="For Free" /></span>
      <p className={styles.instructionsModal}>Welcome to Rivet! Sign-up quickly using your email address or Web3 wallet. To sign-up with an email address, we'll send you a verification code to confirm your new account.</p>
      <div className={styles.center}>
      <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.fieldsContainer}>
      <div className={styles.orgContainer}>
      <label className={styles.orgLabel}>Organization:</label><input
      type="input"
      name="orgname"
      id="orgname"
      placeholder="Enter a nickname or project name."
      className={styles.formControl}
      />
      </div>
      <div className={styles.emailContainer}>
      <label className={styles.emailLabel}>Email:</label><input
      type="email"
      name="email"
      id="email"
      placeholder="We'll send an activation code here."
      className={styles.formControl}
      />
      </div>
      </div>
      <div className={styles.arrowButton}>
      <input
      type="submit"
      value="submit"
      className={styles.submit}
      action="/buidl"
      />
      </div>
      <Web3Login onError={handleLoginError} >Or Sign Up with Web3</Web3Login>
      </form>
      </div>

      </section>
    )
  }

}

export default Buidl
